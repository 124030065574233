import React, { useState, useEffect } from "react";
import "./Footer.css"


const Footer = () => {
  return (
    <div className='footer-layout'>
       <div className='c6-text01'>Tel: 010-9326-7617</div> 
       <div className='c6-text01'>E-mail: chotiger71@momgagiyo.com</div> 

      <div className='footer-bottom'>
        <div style={{diplay:'flex', width:'70%'}}>
          <div className='c6-text02'>주소:(13466) 경기도 성남시 분당구 운중로 124, 8층 804호 비-42(운중동)</div>
          <div className='c6-text02'>주식회사 맘가지요 | 대표:조형기</div>
        </div>
      
        <div className='c6-logolayout' >
          
          <div className='c6-logo' />
          
        </div>
      </div>
    </div>
  )
}

export default Footer
