import React, { useState, useEffect } from 'react';
import EstimateButton from '../component/EstimateButton';
import { Button } from '@mui/material';

const Header = (props) => {
  const [isVisible01, setIsVisible01] = useState(true);
  

  useEffect(() => {
    const handleScroll = () => {
      // 현재 스크롤 위치 가져오기
      const scrollPosition = window.scrollY;

      // 스크롤 위치에 따라 상태 변경
      if (scrollPosition < 100) {
        setIsVisible01(true); 
        // console.log('isAnimationActive:', isAnimationActive)
        // setIsAnimationActive(true)
      }  else {
        setIsVisible01(false);
      }

    }   

    // 스크롤 이벤트 리스너 추가
    window.addEventListener('scroll', handleScroll);

    // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); // 빈 배열은 컴포넌트가 마운트될 때 한 번만 실행

  return ( 
    <div>
      {isVisible01 && <div style={{position:'fixed', top:0, left:0, right:0, zIndex:2}}>
          <div style={{height:'100px', background:'rgba(255,255,255,0.6)', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
              <img src="/images/logo_white.png" style={{height:"40px", marginLeft:'100px', background:'transparent'}} alt="logo"  />
              
              <EstimateButton  refElement={props.refElement} />
              
          </div>
      </div>}
    </div>
  )
}

export default Header