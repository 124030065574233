import React from 'react'

const Content01 = () => {
  return (
    <div>
        <img src="/images/content01_1.jpeg" style={{width:'100%', height:'800px', objectFit: 'cover'}} alt="background"  />
        <div style={{position:'absolute', top:'200px', left:'auto', marginLeft:'200px', fontSize:'46px', color:'rgb(33 117 58)', fontWeight:'bolder'}}>직원 건강 복지,</div>
        <div style={{position:'absolute', top:'250px', left:'auto', marginLeft:'200px', fontSize:'46px', color:'black', fontWeight:'normal'}}>더 이상 고민하지 마세요!</div>
        <div style={{position:'absolute', top:'320px', left:'auto', marginLeft:'200px', fontSize:'20px', color:'black', fontWeight:'normal'}}>
            <div style={{display:'block'}}><b>식단의발견</b>은 미네랄, 중금속 등</div>
            <div style={{display:'block'}}>개인 영양 상태를 정밀히 파악하여</div>
            <div style={{display:'block'}}>1:1 맞춤 상담을 진행하는 체험 프로그램입니다.</div>
        </div>
        

    </div>
  )
}

export default Content01