import Content01 from './pages/Content01';
import Content02 from './pages/Content02';
import Content03 from './pages/Content03';
import Content04 from './pages/Content04';
import Content05 from './pages/Content05';
import Content06 from './pages/Content06';
import Footer from './pages/Footer';
import Header from './pages/Header'
import React, { useState, useRef } from "react";

function App() {

  const refElement = useRef(null)

  return (
    <div>
      <Header refElement={refElement} />
      <Content01 />
      <Content02 />
      <Content03 />
      <Content04 />
      <Content05 />
    <Content06  refElement={refElement} />
      <Footer />
    </div>
  );
}

export default App;
