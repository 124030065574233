import React, { useState, useEffect } from "react";
import "./Content05.css"

const data = [
  {
    id: 1,
    image:
      '/images/6-후기-1.png',
    name: 'A 팀장 (48세)',
    title: '금융회사 영업부',
    quote:
      `"평소에 당뇨 때문에 고민이 많았는데 <span> 꼭 필요한 식단을 추천해 주었어요. </span>"`,
  },
  {
    id: 2,
    image: '/images/6-후기-2.png',
    name: 'K 과장 (36세)',
    title: '홍보대행사 인사팀',
    quote:
      `"직원들이 <span>특별한 개인 맞춤형 복지</span>라고 느끼더라구요."`,
  },
  {
    id: 3,
    image: '/images/6-후기-3.png',
    name: 'Y 사원 (27세)',
    title: '학원 디자이너',
    quote:
      '"솔직히 식단 바꿔봤자 얼마나 도움될까 싶었는데 <span>오후 피로도가 많이 줄었어요."</span>',
  },
  {
    id: 4,
    image: '/images/6-후기-4.png',
    name: 'C 대리 (32세)',
    title: '소프트웨어 개발 엔지니어',
    quote:
      '"<span>합리적인 가격으로 만족도 높은 복지</span>를 누릴 수 있어 좋아요."'
  },
];

const positionArr = ['p01', 'p02', 'p03', 'p04', 'p05', 'p06', 'p07', 'p08']

const Content05 = () => {
  const [people, setPeople] = useState(data);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const lastIndex = people.length - 1;
    if (index < 0) {
      setIndex(lastIndex);
    }
    if (index > lastIndex) {
      setIndex(0);
    }
  }, [index, people]);

  useEffect(() => {
    let slider = setInterval(() => {
      setIndex(index + 1);
      // console.log('index,', index)
    }, 3000);
    return () => clearInterval(slider);
  }, [index]);

  return (
    <div className='c5-layout-01' >
      <div className='c5-thumb' />
      <div className='c5-content' >
        <div className='c5-title'># 직원들이 <span>감동</span>한 서비스 후기</div>
        <section className="section">
          
          <div className="section-center">
            {people.map((person, personIndex) => {
              // console.log('persionIndex',index, personIndex)
              const { id, image, name, title, quote } = person;
              let position = 'p05'
              if (index == 0)  {
                if (personIndex == 0) position = positionArr[3]
                else if (personIndex == 1) position = positionArr[3]
                else if (personIndex == 2) position = positionArr[3]
                else if (personIndex == 3) position = positionArr[3]
              }
              else if (index == 1)  {
                if (personIndex == 0) position = positionArr[0]
                else if (personIndex == 1) position = positionArr[0]
                else if (personIndex == 2) position = positionArr[0]
                else if (personIndex == 3) position = positionArr[6]
              }
              else if (index == 2)  {
                if (personIndex == 0) position = positionArr[1]
                else if (personIndex == 1) position = positionArr[1]
                else if (personIndex == 2) position = positionArr[5]
                else if (personIndex == 3) position = positionArr[5]
              }
              else if (index == 3)  {
                if (personIndex == 0) position = positionArr[2]
                else if (personIndex == 1) position = positionArr[4]
                else if (personIndex == 2) position = positionArr[4]
                else if (personIndex == 3) position = positionArr[4]
              }

              // let position = "a01";
              // if (personIndex === index) {
              //   position = "activeSlide";
              // }
              // if (
              //   personIndex === index - 1 ||
              //   (index === 0 && personIndex === people.length - 1)
              // ) {
              //   position = "lastSlide";
              // }
              return (
                // <article key={id} className={position}>
                <article key={id} className={position}>             
                  <div className='article-box'>
                    <p className="c5-article-text" dangerouslySetInnerHTML={{__html: quote }}></p>
                    {/* <p className="c5-article-text">{quote}</p> */}
                    <img src={image} alt={name} className="person-img" />
                    <h4>{name}</h4>
                    <p className="c5-article-title">{title}</p>
                    
                    {/* <FaQuoteRight className="icon" /> */}
                  </div>
                </article>
              );
            })}
            {/* <button className="prev" onClick={() => setIndex(index - 1)}>
              <FiChevronLeft />
            </button>
            <button className="next" onClick={() => setIndex(index + 1)}>
              <FiChevronRight />
            </button> */}
          </div>
        </section>
      </div>
    </div>
  )
}

export default Content05