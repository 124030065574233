import React, { useState, useRef } from "react";
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';


const EstimateButton = (props) => {
  

  return (
    <div>
        <Button variant="text" 
        style={{paddingLeft:'30px', paddingRight:'40px', paddingTop:'5px', paddingBottom:'5px', marginRight:'100px', background:'white', color:'black', fontSize:'20px',borderRadius: 18}} 
        // onClick={()=> {props.refElement.current.scrollIntoView({ behavior: 'smooth', block: 'start' })}} ><b>견적 문의하기</b></Button>
        onClick={()=> {window.scrollTo(0, props.refElement.current.offsetTop)}} ><b>견적 문의하기</b></Button>
 
    </div>
  )
}

export default EstimateButton