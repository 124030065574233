import React, { useState, useEffect } from 'react';
import "./Content02.css"

const Content02 = () => {
    const [isVisible01, setIsVisible01] = useState(false);
    const [isVisible02, setIsVisible02] = useState(false);
    const [isVisible03, setIsVisible03] = useState(false);
    const [isVisible04, setIsVisible04] = useState(false);
    const [isVisible05, setIsVisible05] = useState(false);
    const [isAnimationActive, setIsAnimationActive] = useState(false);
    
 
// call this to Disable


    useEffect(() => {
        // 애니메이션이 끝난 후에 스크롤을 풀기 위한 처리
        if (isAnimationActive) {
            console.log('scroll stop')
            // disableScroll()
        }
        else {
          const timeoutId = setTimeout(() => {
            // enableScroll()
            clearTimeout(timeoutId);
          }, 1000); // 애니메이션의 지속 시간과 일치하게 설정 (1초)
        }
      }, [isAnimationActive]);

    useEffect(() => {
        const handleScroll = () => {
          // 현재 스크롤 위치 가져오기
          const scrollPosition = window.scrollY;
    
          // 스크롤 위치에 따라 상태 변경
          if (scrollPosition > 100) {
            setIsVisible01(true); 
            // console.log('isAnimationActive:', isAnimationActive)
            setIsAnimationActive(true)
          }  else {
            setIsVisible01(false);
          }

          if (scrollPosition > 300) {
            setIsVisible02(true); 
            setIsAnimationActive(true)
          }  else {
            setIsVisible02(false);
          }

          if (scrollPosition > 500) {
            setIsVisible03(true); 
            setIsAnimationActive(true)
          }  else {
            setIsVisible03(false);
          }

          if (scrollPosition > 600) {
            setIsVisible04(true); 
            setIsAnimationActive(true)
          }  else {
            setIsVisible04(false);
          }

          if (scrollPosition > 700) {
            setIsVisible05(true); 
            setIsAnimationActive(true)
          }  else {
            setIsVisible05(false);
          }
        };
    
        // 스크롤 이벤트 리스너 추가
        window.addEventListener('scroll', handleScroll);
    
        // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []); // 빈 배열은 컴포넌트가 마운트될 때 한 번만 실행
    
  return (
    <div style={{height:'800px', display: 'flex', flexDirection: 'column', alignItems:'center', transition: 'all 2s'}}>
        <div style={{marginTop:'100px', fontSize:'36px', fontWeight:'bold', color:'rgb(128 128 128)', marginBottom:'20px'}}>요즘 직원들이 <span style={{ color:'rgb(33 117 58)'}}>건강 문제</span>에 관심이 많던데...</div>
        
        {isVisible01 && <div className='c2-element01'>
            <div><span style={{fontWeight:'bold'}}>피로와 스트레스로 지친 직원들</span>을 도울 방법이 없을까?</div>
            </div>}
        {isVisible02 && <div className='c2-element01'>
            <span style={{fontWeight:'bold'}}>복지 예산,&ensp;</span>최대한 합리적으로 사용할 수는 없을까?</div>}
        {isVisible03 && <div className='c2-element01' style={{marginBottom:'40px'}}>우리 회사만 제공할 수 있는&ensp;<span style={{fontWeight:'bold'}}>더 특별한 복지</span>는 없을까?</div>}
      
        {isVisible04 && <div className='c2-element02'></div>}
        {isVisible04 && <div className='c2-element02'></div>}
        {isVisible04 && <div className='c2-element02'></div>}

        {isVisible05 && <div className='c2-element03'>
            <div style={{display:'block', margin:'5px'}}>이 모든 고민을 </div>
            <div style={{display:'block', margin:'5px'}}><span style={{ color:'rgb(33 117 58)'}}>식단의 발견</span>이</div>
            <div style={{display:'block', margin:'5px'}}>한 번에 해결해 드립니다. </div>
            </div>}
    </div>
  )
}

export default Content02