import React, { useState, useEffect } from 'react';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import "./Content03.css"

const Content03 = () => {
    const [isVisible01, setIsVisible01] = useState(false);
    const [isVisible02, setIsVisible02] = useState(false);
    const [isVisible03, setIsVisible03] = useState(false);
    const [isVisible04, setIsVisible04] = useState(false);
    const [open, setOpen] = useState(false);
    const [selectImage, setSelectImage] = useState(0);
    
    useEffect(() => {
        const handleScroll = () => {
          // 현재 스크롤 위치 가져오기
          const scrollPosition = window.scrollY;
    
          // 스크롤 위치에 따라 상태 변경
          if (scrollPosition > 200) {
            setIsVisible01(true); 
            // console.log('isAnimationActive:', isAnimationActive)
            // setIsAnimationActive(true)
          }  else {
            setIsVisible01(false);
          }

          if (scrollPosition > 500) {
            setIsVisible02(true); 
            // console.log('isAnimationActive:', isAnimationActive)
            // setIsAnimationActive(true)
          }  else {
            setIsVisible02(false);
          }

          if (scrollPosition > 800) {
            setIsVisible03(true); 
            // console.log('isAnimationActive:', isAnimationActive)
            // setIsAnimationActive(true)
          }  else {
            setIsVisible03(false);
          }


          if (scrollPosition > 2600) {
            setIsVisible04(true); 
            // console.log('isAnimationActive:', isAnimationActive)
            // setIsAnimationActive(true)
          }  else {
            setIsVisible04(false);
          }

        };
    
        // 스크롤 이벤트 리스너 추가
        window.addEventListener('scroll', handleScroll);
    
        // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []); // 빈 배열은 컴포넌트가 마운트될 때 한 번만 실행

  return (
    <div style={{}}>
        <div style={{ background:'rgb(33 117 58)', display:'flex', flexDirection:'column'}}>
            {isVisible01 && <div className='c3-element01'>
                <div >직원 1:1 영양 코칭부터 </div>
                <div >맞춤 식단 추천까지, <span style={{color:'rgb(247 172 66)'}}>모두 한 번에!</span></div>
                 </div> }

            {isVisible02 && <div className='c3-element02'>
                <div>
                    <div className='c3-step'>STEP 1.</div>
                    <div className='c3-title'>견적 문의</div>
                    <div className='c3-content01'>임직원 수에 따른</div>
                    <div className='c3-content02'>빠른 견적 전송</div>
                </div>
            </div>}

            {isVisible03 && <div className='c3-element03'>
                <div>
                    <div className='c3-step'>STEP 2.</div>
                    <div className='c3-title'>영양코칭 프로그램 진행</div>
                    <div className='c3-mid-box'>
                        <div>
                            <div className='c3-mid-box-stack' />
                            <div className='c3-mid-box-title'>개인 영양상태 정밀검사</div>
                        </div>
                        <div className='c3-mid-box_1'>
                            <div className='c3-mid-box_1_01' onClick={() => {setOpen(true);setSelectImage(0)}}/>
                            <div className='c3-mid-box_1_02' onClick={() => {setOpen(true);setSelectImage(1)}}/>
                            <div className='c3-mid-box_1_03' onClick={() => {setOpen(true);setSelectImage(2)}}/>
                        </div>

                        <Lightbox
                            open={open}
                            close={() => setOpen(false)}
                            index={selectImage}
                            slides={[
                                { src: "/images/content03_2_01.png"},
                                { src: "/images/content03_2_02.png"},
                                { src: "/images/content03_2_03.png"},
                            ]}
                        />

                        
                    </div>

                    <div className='c3-mid-box_2 img03-3-01'>
                        
                        <div style={{float:'top'}}>
                            <div className='c3-mid-box-stack-02' />
                            <div className='c3-mid-box-title-02'>전문 상담가의 1:1 밀착 코칭</div>
                        </div>
                    {isVisible04 && 
                        <div>
                            <div className='c3-mobile01' />
                            <div className='c3-mobile02' />
                        </div>}
                    </div> 

                    <div className='c3-mid-box_2 img03-3-02'>
                        
                        <div style={{float:'top'}}>
                            <div className='c3-mid-box-stack-02' />
                            <div className='c3-mid-box-title-02'>실전을 통한 식습관 개선</div>
                        </div>
                    </div>
                </div>
               
            </div>}
            
        </div>
    </div>
  )
}

export default Content03