import React, { useState, useEffect } from 'react';
import "./Content04.css"

const Content04 = () => {
  const [isVisible01, setIsVisible01] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      // 현재 스크롤 위치 가져오기
      const scrollPosition = window.scrollY;

      // 스크롤 위치에 따라 상태 변경
      if (scrollPosition > 3000) {
        setIsVisible01(true); 
        // console.log('isAnimationActive:', isAnimationActive)
        // setIsAnimationActive(true)
      }  else {
        setIsVisible01(false);
      }

    };

    // 스크롤 이벤트 리스너 추가
    window.addEventListener('scroll', handleScroll);

    // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); // 빈 배열은 컴포넌트가 마운트될 때 한 번만 실행
  return (
    <div >
      
      <div className='c4-backcolor'  style={{ display:'flex', flexDirection:'column'}}>
        <div className='c4-element04' style={{ display:'flex', flexDirection:'row'}}>
          <div>
              <div className='c3-step'>STEP 3.</div>
              <div className='c3-title'>맞춤 식단 추천</div>
              <div className='c3-content01'>개별 건강 및 영양 상태에 따른</div>
              <div className='c3-content02'><span>맞춤형 식단 & 스케쥴 추천</span></div>
          </div>
          <div style={{width:'80%', display:'flex', background:'transparent', flexDirection:'row', alignItems:'end', justifyContent: 'space-around' }}>
          {isVisible01 && <div className='c4-img01' />}
          {isVisible01 && <div className='c4-img02' />}
          {isVisible01 && <div className='c4-img03' />}
          </div>

        </div>

        
      </div>
      
    </div>
  )
}

export default Content04