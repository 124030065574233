import React, { useState, useRef } from "react";
import "./Content06.css"
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };


const Content06 = (props) => {
  const [privacyCheck, setPrivacyCheck] = useState(false);
  const [company, setCompany] = useState('');
  const [department, setDepartment] = useState('');
  const [username, setUsername] = useState('');
  const [phonenumber, setPhonenumber] = useState('');
  const [email, setEmail] = useState('');
  const [employee, setEmployee] = useState('');
  const [question, setQuestion] = useState('');
  const [error, setError] = useState(null);

  const handleSubmitButton = () => {
   console.log(company, department, username, phonenumber, email, employee, question)
   // send mail
    const data = {                      //현재의 email state값을 data객체로 감쌌다
      company: company,
      department: department,
      username: username,
      phonenumber: phonenumber,
      email: email,
      employee: employee,
      question: question
    }
    setTimeout(() => {
      fetch('https://api.food-discovery.com/sendEmail',{      //sendEmail 라우터로 보내버리기
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
      })
      .then(res => {
        // res.json()
        toast.success("견적내용이 메일로 전송되었습니다. 감사합니다.", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      })
      .then(json => {
          
      })
      .catch(err => {
        console.log(err)
        // setIsPending(false);
        
        toast.error("서버와의 연동에 문제가 있습니다.", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      //에러시 Loading메세지 사라지고
      //에러메세지만 보이도록 설정
      });
    }, 1000);
  
  }

  return (
    
    <div ref={props.refElement}  className='c6-layout' >
      <div className='c6-title'>
      <span>건강한 직원</span>이 <span>건강한 회사</span>를 만듭니다.
      </div>
      <div className='c6-subtitle'> 
      우리 직원들 영양 복지, SKIP 하지 마세요.
      </div>
      <div className='c6-fromrow' style={{marginTop:'4rem'}}>
        <div className='c6-formlabel'>회사명</div>
        <TextField id="outlined-basic" label="회사명" variant="outlined" className='c6-textfield' sx={{backgroundColor: '#fff'}}
          onChange={event => {const {value} = event.target; setCompany(value)}}/>
      </div>
      <div className='c6-fromrow' >
        <div className='c6-formlabel'>담당부서</div>
        <TextField id="outlined-basic" label="담당부서" variant="outlined" className='c6-textfield' sx={{backgroundColor: '#fff'}} 
        onChange={event => {const {value} = event.target; setDepartment(value)}}/>
      </div>

      <div className='c6-fromrow' >
        <div className='c6-formlabel'>성함</div>
        <TextField id="outlined-basic" label="성함" variant="outlined" className='c6-textfield' sx={{backgroundColor: '#fff'}} 
        onChange={event => {const {value} = event.target; setUsername(value)}}/>
      </div>
      <div className='c6-fromrow' >
        <div className='c6-formlabel'>연락처</div>
        <TextField id="outlined-basic" label="연락처" variant="outlined" className='c6-textfield' sx={{backgroundColor: '#fff'}} 
        onChange={event => {const {value} = event.target; setPhonenumber(value)}}/>
      </div>
      <div className='c6-fromrow' >
        <div className='c6-formlabel'>이메일</div>
        <TextField id="outlined-basic" label="이메일" variant="outlined" className='c6-textfield' sx={{backgroundColor: '#fff'}} 
        onChange={event => {const {value} = event.target; setEmail(value)}}/>
      </div>
      <div className='c6-fromrow' >
        <div className='c6-formlabel'>직원수</div>
        <TextField id="outlined-basic" label="직원수" variant="outlined" className='c6-textfield' sx={{backgroundColor: '#fff'}} 
        onChange={event => {const {value} = event.target; setEmployee(value)}}/>
      </div>
      <div className='c6-fromrow' >
        <div className='c6-formlabel'>문의 내용</div>
        <TextField id="standard-multiline-flexible" label="문의 내용" variant="outlined" multiline minRows={4} className='c6-textfield'  sx={{backgroundColor: '#fff'}} 
        onChange={event => {const {value} = event.target; setQuestion(value)}}/>
      </div>
      <div className='c6-checkrow'>
        <FormControlLabel control={<Checkbox  />} label={<span style={{ fontSize: '1.6rem' }}>{"개인정보처리방침에 동의합니다."}</span>} className='c6-check' onChange={e => {
          console.log(e.target.checked);
          setPrivacyCheck(e.target.checked)
        }}  />
      </div>
      <div className='c6-infobox'>
        식단의발견(이하 ‘식단의발견’이라 한다)은 개인정보 보호법 제30조에 따라 정보 주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리지침을 수립, 공개합니다.
      </div>
      {/* {error && 
        toast.error("Error Notification !", {
          position: toast.POSITION.BOTTOM_CENTER,
        })
      } */}
      <ToastContainer />
      <Button variant="contained" color="success" style={{marginTop:'3rem', height:'5rem', width:'40rem', fontSize:'2.6rem', fontWeight:'bold', borderRadius:'2.5rem', marginBottom:'6rem'}} 
      disabled={!privacyCheck} onClick={handleSubmitButton}>빠른 견적 받기
        </Button>


    </div>
  )
}

export default Content06